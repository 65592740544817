@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,700");

$yellow: #f6f792;
$black: #333745;
$blue: #77c4d3;
$lightBlue: #daede2;
$red: #ea2e49;
$white: #fff;

$mainFont: "Open Sans", sans-serif;

// PRICING TABLE

// INDIVIDUAL PRICING TABLE
.PriceTable .card {
  width: 230px;
  margin: auto;
  height: 420px;
  padding: 30px;
  border-radius: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
  box-shadow: 2px 2px 46px -4px rgba(0, 0, 0, 0.6);
  transition: all 0.2s ease-in-out;

  // SPECIFIC STYLING FOR THE FIRST TABLE

  // SPECIFIC STYLING FOR THE SECOND TABLE
  &:nth-child(2) {
    margin: 0 30px;
    z-index: 2;
    width: 300px;
    height: 430px;
    background: #00b09b;
    background: linear-gradient(to right, #96c93d, #00b09b);

    .price {
      &::before {
        content: "$50";
      }
    }
  }

  // SPECIFIC STYLING FOR THE THIRD TABLE
  &:nth-child(1) {
    margin: 0 30px;
    z-index: 2;
    width: 300px;
    height: 430px;
    background: #ff416c;
    background: linear-gradient(to right, #ff4b2b, #ff416c);

    .price {
      &::before {
        content: "$50";
      }
    }
  }

  &:nth-child(3) {
    margin: 0 30px;
    z-index: 2;
    width: 300px;
    height: 430px;
    background: #7f7fd5;
    background: linear-gradient(to left, #91eae4, #86a8e7, #7f7fd5);

    .price {
      &::before {
        content: "$50";
      }
    }
  }

  &:nth-child(4) {
    margin: 0 30px;
    z-index: 2;
    width: 300px;
    height: 430px;
    background: #00b09b;
    background: linear-gradient(to right, #96c93d, #00b09b);

    .price {
      &::before {
        content: "$50";
      }
    }
  }

  // SPECIFIC STYLING FOR THE THIRD TABLE
  &:nth-child(5) {
    margin: 0 30px;
    z-index: 2;
    width: 300px;
    height: 430px;
    background: #ff416c;
    background: linear-gradient(to right, #ff4b2b, #ff416c);

    .price {
      &::before {
        content: "$50";
      }
    }
  }

  &:nth-child(6) {
    margin: 0 30px;
    z-index: 2;
    width: 300px;
    height: 430px;
    background: #7f7fd5;
    background: linear-gradient(to left, #91eae4, #86a8e7, #7f7fd5);

    .price {
      &::before {
        content: "$50";
      }
    }
  }

  // SPECIFIC STYLING FOR THE FIRST AND LAST TABLE
  //   &:nth-child(1),
  //   &:nth-child(3) {
  //     .price {
  //       font-size: 6rem;
  //     }

  //     .buy-button {
  //       width: 230px;
  //       height: 170px;
  //       h3 {
  //         top: 12%;
  //         left: 10%;
  //         font-size: 1rem;
  //       }
  //     }
  //   }

  // TYPE OF SUBSCRIPTION
  .type {
    margin-top: 30px;
    letter-spacing: 0.1rem;
  }

  // PRICE
  .price {
    font-size: 7.5rem;
    position: relative;
    margin: 10px 0px 20px;
    z-index: 2;

    span {
      font-size: 1.8rem;
      position: absolute;
      left: -15%;
      top: 65%;
    }

    &::before {
      position: absolute;
      content: "";
      color: rgba($white, 0.06);
      font-size: 9.5rem;
      z-index: -1;
      right: -30%;
      bottom: 15%;
      text-shadow: 0 0 0px rgba($black, 0);
      transition: all 1s ease-in-out;
    }
  }

  // PLAN WITH THE TWO BORDERS ON EACH SIDE
  .plan {
    font-size: 1.3rem;
    position: relative;
    margin-bottom: 10px;

    &::before,
    &::after {
      position: absolute;
      content: "";
      width: 35px;
      height: 2px;
      background: $white;
      bottom: 40%;
      transition: all 0.2s ease-out;
    }

    &::before {
      right: 100%;
      transform: translate(-50%, -50%);
    }
    &::after {
      right: -100%;
      transform: translate(-0%, -50%);
    }
  }

  // DETAILS OF THE PLAN
  .details {
    text-transform: capitalize;
    li {
      margin: 15px 0px;
    }
  }

  // BUTTON CONTAINER
  .buy-button {
    cursor: pointer;
    position: absolute;
    width: 250px;
    height: 180px;
    background: $white;
    border-radius: 15%;
    right: -34%;
    bottom: -27%;
    transition: all 0.4s ease-in-out;

    // BUTTON
    h3 {
      text-shadow: 0 0 0;
      text-decoration: none;
      color: $white;
      position: absolute;
      left: 8%;
      top: 10%;
      color: $black;
      font-size: 1.2rem;
      transition: all 0.4s ease-in-out;
    }
  }

  // HOVER
  &:hover {
    transform: scale(1.02);

    // PRICE
    .price {
      &::before {
        animation: text-hover 1s ease-in-out infinite normal;
      }
    }

    // PLAN
    .plan {
      &::before {
        right: 90%;
      }
      &::after {
        right: -90%;
      }
    }

    // HOVER BUTTON
    .buy-button {
      width: 100%;
      right: 0%;
      border-radius: 0%;
      h3 {
        left: 50%;
        transform: translate(-50%, 0%);
      }
    }
  }
}

// KEYFRAMES
@keyframes text-hover {
  0% {
    right: -30%;
  }
  50% {
    right: -37%;
  }
  100% {
    right: -30%;
  }
}

.Choose_me .content li {
  width: 100%;
  display: flex;
  justify-content: center;
}

.Choose_me .content {
  position: absolute;
  top: 120%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 160px;
  overflow: hidden;
  font-family: "Lato", sans-serif;
  font-size: 20px;
  line-height: 40px;
  // color: #ecf0f1;
  text-transform: uppercase;
  color: #ff4b2b;
  width: 100%;
  display: flex;
  justify-content: center;

  &__container {
    font-weight: 600;
    overflow: hidden;
    height: 40px;
    // padding: 0 40px;

    &:before {
      content: "[";
      left: 0;
    }

    &:after {
      content: "]";
      position: absolute;
      right: 0;
    }

    &:after,
    &:before {
      position: absolute;
      top: 0;
      color: #0e640b;
      font-size: 42px;
      line-height: 40px;
      -webkit-animation-name: opacity;
      -webkit-animation-duration: 2s;
      -webkit-animation-iteration-count: infinite;
      animation-name: opacity;
      animation-duration: 2s;
      animation-iteration-count: infinite;
    }

    &__text {
      display: inline;
      float: left;
      margin: 0;
    }

    &__list {
      margin-top: 0;
      // padding-left: 110px;
      text-align: left;
      list-style: none;

      -webkit-animation-name: change;
      -webkit-animation-duration: 10s;
      -webkit-animation-iteration-count: infinite;
      animation-name: change;
      animation-duration: 10s;
      animation-iteration-count: infinite;

      &__item {
        line-height: 40px;
        margin: 0;
      }
    }
  }
}

@media screen and (min-width: 200px) and (max-width: 480px) {
  .Choose_me .content {
    font-size: 14px;
    padding: 0;
    margin: 0;
  }
}

@-webkit-keyframes opacity {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-webkit-keyframes change {
  0%,
  12.66%,
  100% {
    transform: translate3d(0, 0, 0);
  }
  16.66%,
  29.32% {
    transform: translate3d(0, -25%, 0);
  }
  33.32%,
  45.98% {
    transform: translate3d(0, -50%, 0);
  }
  49.98%,
  62.64% {
    transform: translate3d(0, -75%, 0);
  }
  66.64%,
  79.3% {
    transform: translate3d(0, -50%, 0);
  }
  83.3%,
  95.96% {
    transform: translate3d(0, -25%, 0);
  }
}

@-o-keyframes opacity {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-o-keyframes change {
  0%,
  12.66%,
  100% {
    transform: translate3d(0, 0, 0);
  }
  16.66%,
  29.32% {
    transform: translate3d(0, -25%, 0);
  }
  33.32%,
  45.98% {
    transform: translate3d(0, -50%, 0);
  }
  49.98%,
  62.64% {
    transform: translate3d(0, -75%, 0);
  }
  66.64%,
  79.3% {
    transform: translate3d(0, -50%, 0);
  }
  83.3%,
  95.96% {
    transform: translate3d(0, -25%, 0);
  }
}

@-moz-keyframes opacity {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-moz-keyframes change {
  0%,
  12.66%,
  100% {
    transform: translate3d(0, 0, 0);
  }
  16.66%,
  29.32% {
    transform: translate3d(0, -25%, 0);
  }
  33.32%,
  45.98% {
    transform: translate3d(0, -50%, 0);
  }
  49.98%,
  62.64% {
    transform: translate3d(0, -75%, 0);
  }
  66.64%,
  79.3% {
    transform: translate3d(0, -50%, 0);
  }
  83.3%,
  95.96% {
    transform: translate3d(0, -25%, 0);
  }
}

@keyframes opacity {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes change {
  0%,
  12.66%,
  100% {
    transform: translate3d(0, 0, 0);
  }
  16.66%,
  29.32% {
    transform: translate3d(0, -25%, 0);
  }
  33.32%,
  45.98% {
    transform: translate3d(0, -50%, 0);
  }
  49.98%,
  62.64% {
    transform: translate3d(0, -75%, 0);
  }
  66.64%,
  79.3% {
    transform: translate3d(0, -50%, 0);
  }
  83.3%,
  95.96% {
    transform: translate3d(0, -25%, 0);
  }
}
