@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@600&family=Noto+Sans&family=Prompt:wght@500&family=REM&display=swap");

:root {
  --background: #1a1a2e;
  --color: #ffffff;
  --primary-color: #0f3460;
}

html {
  scroll-behavior: smooth;
}
.Admin_Login {
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  background: var(--background);
  color: var(--color);
  letter-spacing: 1px;
  transition: background 0.2s ease;
  -webkit-transition: background 0.2s ease;
  -moz-transition: background 0.2s ease;
  -ms-transition: background 0.2s ease;
  -o-transition: background 0.2s ease;
}

.Admin_Login a {
  text-decoration: none;
  color: var(--color);
}

.Admin_Login h1 {
  font-size: 2.5rem;
}

.Admin_Login .container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.Admin_Login .login-container {
  position: relative;
  width: 22.2rem;
}
.Admin_Login .form-container {
  border: 1px solid hsla(0, 0%, 65%, 0.158);
  box-shadow: 0 0 36px 1px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  backdrop-filter: blur(20px);
  z-index: 99;
  padding: 2rem;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.Admin_Login .login-container form input {
  display: block;
  padding: 14.5px;
  width: 100%;
  margin: 2rem 0;
  color: var(--color);
  outline: none;
  background-color: #9191911f;
  border: none;
  border-radius: 5px;
  font-weight: 500;
  letter-spacing: 0.8px;
  font-size: 15px;
  backdrop-filter: blur(15px);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.login-container form input:focus {
  box-shadow: 0 0 16px 1px rgba(0, 0, 0, 0.2);
  animation: wobble 0.3s ease-in;
  -webkit-animation: wobble 0.3s ease-in;
}

.login-container form button {
  background-color: var(--primary-color);
  color: var(--color);
  display: block;
  padding: 13px;
  border-radius: 5px;
  outline: none;
  font-size: 18px;
  letter-spacing: 1.5px;
  font-weight: bold;
  width: 100%;
  cursor: pointer;
  margin-bottom: 2rem;
  transition: all 0.1s ease-in-out;
  border: none;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
}

.login-container form button:hover {
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.15);
  transform: scale(1.02);
  -webkit-transform: scale(1.02);
  -moz-transform: scale(1.02);
  -ms-transform: scale(1.02);
  -o-transform: scale(1.02);
}

.circle {
  width: 8rem;
  height: 8rem;
  background: var(--primary-color);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  position: absolute;
}

.illustration {
  position: absolute;
  top: -14%;
  right: -2px;
  width: 90%;
}

.circle-one {
  top: 0;
  left: 0;
  z-index: -1;
  transform: translate(-45%, -45%);
  -webkit-transform: translate(-45%, -45%);
  -moz-transform: translate(-45%, -45%);
  -ms-transform: translate(-45%, -45%);
  -o-transform: translate(-45%, -45%);
}

.circle-two {
  bottom: 0;
  right: 0;
  z-index: -1;
  transform: translate(45%, 45%);
  -webkit-transform: translate(45%, 45%);
  -moz-transform: translate(45%, 45%);
  -ms-transform: translate(45%, 45%);
  -o-transform: translate(45%, 45%);
}

.register-forget {
  margin: 1rem 0;
  display: flex;
  justify-content: space-between;
}
.opacity {
  opacity: 0.6;
}

.theme-btn-container {
  position: absolute;
  left: 0;
  bottom: 2rem;
}

.theme-btn {
  cursor: pointer;
  transition: all 0.3s ease-in;
}

.theme-btn:hover {
  width: 40px !important;
}

@keyframes wobble {
  0% {
    transform: scale(1.025);
    -webkit-transform: scale(1.025);
    -moz-transform: scale(1.025);
    -ms-transform: scale(1.025);
    -o-transform: scale(1.025);
  }
  25% {
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
  }
  75% {
    transform: scale(1.025);
    -webkit-transform: scale(1.025);
    -moz-transform: scale(1.025);
    -ms-transform: scale(1.025);
    -o-transform: scale(1.025);
  }
  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
  }
}

.Offer_Tale {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
}

.Id_Card {
  margin-top: 30px;
}

@media screen and (min-width: 480px) and (max-width: 2000px) {
  .Id_Card {
    width: 500px;
  }
}

.Id_Card > div {
  width: 450px !important;
  border-radius: 10px;
  border: 1px solid grey;
  padding: 10px;
  margin-left: 20px;
  background: #0b0e37;
  background: linear-gradient(to right, #015122, #0a8e64);
  color: white;
}
@media screen and (min-width: 200px) and (max-width: 480px) {
  .Id_Card > div {
    width: 95% !important;
    border-radius: 10px;
    border: 1px solid grey;
    margin: 10px;
    padding: 10px;
    background: #0b0e37;
    background: linear-gradient(to right, #015122, #0a8e64);
    color: white;
  }

  .card_Id h6 {
    font-size: 13px;
  }
}

.Hand_Text {
  font-family: "Dancing Script", cursive !important;
  font-family: "Prompt", sans-serif !important;
  font-family: "REM", sans-serif !important;
}
