/* Googlefont Poppins CDN Link */
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap'); */
/* *{ */
/* margin: 0; */
/* padding: 0; */
/* box-sizing: border-box; */
/* font-family: 'Poppins', sans-serif; */
/* } */
.sidebar {
  position: fixed;
  height: 100%;
  width: 240px;
  background: rgb(12, 42, 20);
  transition: all 0.5s ease;
}
.sidebar.active {
  width: 60px;
}
.sidebar .logo-details {
  height: 80px;
  display: flex;
  align-items: center;
}

.sidebar .logo-details img {
  height: 50px;
  margin-left: 17px;
}

.sidebar .logo-details i {
  font-size: 28px;
  font-weight: 500;
  color: #fff;
  min-width: 60px;
  text-align: center;
}
.sidebar .logo-details .logo_name {
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  margin-left: 10px;
}
.sidebar .nav-links {
  margin-top: 10px;
}
.sidebar .nav-links li {
  position: relative;
  list-style: none;
  height: 50px;
}
.sidebar .nav-links li a {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
}
.sidebar .nav-links li a.active {
  background: #084510;
}
.sidebar .nav-links li a:hover {
  background: #084510;
}
.sidebar .nav-links li i {
  min-width: 60px;
  text-align: center;
  font-size: 18px;
  color: #fff;
}
.sidebar .nav-links li a .links_name {
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  white-space: nowrap;
}
.sidebar .nav-links .log_out {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.home-section {
  position: relative;
  background: #f5f5f5;
  min-height: 100vh;
  width: calc(100% - 240px);
  left: 240px;
  transition: all 0.5s ease;
}
.sidebar.active ~ .home-section {
  width: calc(100% - 60px);
  left: 60px;
}
.home-section nav {
  display: flex;
  justify-content: space-between;
  height: 80px;
  background: #fff;
  display: flex;
  align-items: center;
  position: fixed;
  width: calc(100% - 240px);
  left: 240px;
  z-index: 100;
  padding: 0 20px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  transition: all 0.5s ease;
}
.sidebar.active ~ .home-section nav {
  left: 60px;
  width: calc(100% - 60px);
}
.home-section nav .sidebar-button {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 500;
}
nav .sidebar-button i {
  font-size: 35px;
  margin-right: 10px;
}
.home-section nav .search-box {
  position: relative;
  height: 50px;
  max-width: 550px;
  width: 100%;
  margin: 0 20px;
}
nav .search-box input {
  height: 100%;
  width: 100%;
  outline: none;
  background: #f5f6fa;
  border: 2px solid #efeef1;
  border-radius: 6px;
  font-size: 18px;
  padding: 0 15px;
}
nav .search-box .bx-search {
  position: absolute;
  height: 40px;
  width: 40px;
  background: rgb(55, 22, 203);
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 4px;
  line-height: 40px;
  text-align: center;
  color: #fff;
  font-size: 22px;
  transition: all 0.4 ease;
}
.home-section nav .profile-details {
  display: flex;
  align-items: center;
  background: #f5f6fa;
  border: 2px solid #efeef1;
  border-radius: 6px;
  height: 50px;
  min-width: 190px;
  padding: 0 15px 0 10px;
  cursor: pointer;
}
nav .profile-details img {
  height: 40px;
  width: 40px;
  border-radius: 6px;
  object-fit: cover;
  margin-left: 10px;
}
nav .profile-details .admin_name {
  font-size: 15px;
  font-weight: 500;
  color: #333;
  margin: 0 10px;
  white-space: nowrap;
}
nav .profile-details i {
  font-size: 25px;
  color: #333;
}
.home-section .home-content {
  position: relative;
  padding-top: 100px;
}
.home-content .overview-boxes {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 20px;
  margin-bottom: 26px;
}
.overview-boxes .box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% / 4 - 15px);
  background: #fff;
  padding: 15px 14px;
  border-radius: 12px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}
.overview-boxes .box-topic {
  font-size: 20px;
  font-weight: 500;
}
.home-content .box .number {
  display: inline-block;
  font-size: 35px;
  margin-top: -6px;
  font-weight: 500;
}
.home-content .box .indicator {
  display: flex;
  align-items: center;
}
.home-content .box .indicator i {
  height: 20px;
  width: 20px;
  background: #8fdacb;
  line-height: 20px;
  text-align: center;
  border-radius: 50%;
  color: #fff;
  font-size: 20px;
  margin-right: 5px;
}
.box .indicator i.down {
  /* background: #e87d88; */
}
.home-content .box .indicator .text {
  font-size: 12px;
}
.home-content .box .cart {
  display: inline-block;
  font-size: 32px;
  height: 50px;
  width: 50px;
  background: #cce5ff;
  line-height: 50px;
  text-align: center;
  color: rgb(52, 30, 148);
  border-radius: 12px;
  margin: -15px 0 0 6px;
}
.home-content .box .cart.two {
  color: #2bd47d;
  background: #c0f2d8;
}
.home-content .box .cart.three {
  color: #ffc233;
  background: #ffe8b3;
}
.home-content .box .cart.four {
  color: #e05260;
  background: #f7d4d7;
}
.home-content .total-order {
  font-size: 20px;
  font-weight: 500;
}
.home-content .sales-boxes {
  display: flex;
  justify-content: space-between;
  /* padding: 0 20px; */
}

/* left box */
.home-content .sales-boxes .recent-sales {
  width: 65%;
  background: #fff;
  padding: 20px 30px;
  margin: 0 20px;
  border-radius: 12px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}
.home-content .sales-boxes .sales-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sales-boxes .box .title {
  font-size: 24px;
  font-weight: 500;
  /* margin-bottom: 10px; */
}
.sales-boxes .sales-details li.topic {
  font-size: 20px;
  font-weight: 500;
}
.sales-boxes .sales-details li {
  list-style: none;
  margin: 8px 0;
}
.sales-boxes .sales-details li a {
  font-size: 18px;
  color: #333;
  font-size: 400;
  text-decoration: none;
}
.sales-boxes .box .button {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.sales-boxes .box .button a {
  color: #fff;
  background: #0a2558;
  padding: 4px 12px;
  font-size: 15px;
  font-weight: 400;
  border-radius: 4px;
  text-decoration: none;
  transition: all 0.3s ease;
}
.sales-boxes .box .button a:hover {
  background: #0d3073;
}

/* Right box */
.home-content .sales-boxes .top-sales {
  width: 35%;
  background: #fff;
  padding: 20px 30px;
  margin: 0 20px 0 0;
  border-radius: 12px;
  box-shadow: 0 5px 10px rgb(2, 33, 75);
}
.sales-boxes .top-sales li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}
.sales-boxes .top-sales li a img {
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 12px;
  margin-right: 10px;
  background: #333;
}
.sales-boxes .top-sales li a {
  display: flex;
  align-items: center;
  text-decoration: none;
}
.sales-boxes .top-sales li .product,
.price {
  font-size: 17px;
  font-weight: 400;
  color: #333;
}
/* Responsive Media Query */
@media (max-width: 1240px) {
  .sidebar {
    width: 60px;
  }
  .sidebar.active {
    width: 220px;
  }
  .home-section {
    width: calc(100% - 60px);
    left: 60px;
  }
  .sidebar.active ~ .home-section {
    left: 220px;
    width: calc(100% - 220px);
    overflow: hidden;
  }
  .home-section nav {
    width: calc(100% - 60px);
    left: 60px;
  }
  .sidebar.active ~ .home-section nav {
    width: calc(100% - 220px);
    left: 220px;
  }
}
@media (max-width: 1150px) {
  .home-content .sales-boxes {
    flex-direction: column;
  }
  .home-content .sales-boxes .box {
    width: 100%;
    overflow-x: scroll;
    margin-bottom: 30px;
  }
  .home-content .sales-boxes .top-sales {
    margin: 0;
  }
}
@media (max-width: 1000px) {
  .overview-boxes .box {
    width: calc(100% / 2 - 15px);
    margin-bottom: 15px;
  }
}
@media (max-width: 700px) {
  nav .sidebar-button .dashboard,
  nav .profile-details .admin_name,
  nav .profile-details i {
    display: none;
  }
  .home-section nav .profile-details {
    height: 50px;
    min-width: 40px;
  }
  .home-content .sales-boxes .sales-details {
    width: 560px;
  }
}
@media (max-width: 550px) {
  .overview-boxes .box {
    width: 100%;
    margin-bottom: 15px;
  }
  .sidebar.active ~ .home-section nav .profile-details {
    display: none;
  }
}
@media (max-width: 400px) {
  .sidebar {
    width: 0;
  }
  .sidebar.active {
    width: 60px;
  }
  .home-section {
    width: 100%;
    left: 0;
  }
  .sidebar.active ~ .home-section {
    left: 60px;
    width: calc(100% - 60px);
  }
  .home-section nav {
    width: 100%;
    left: 0;
  }
  .sidebar.active ~ .home-section nav {
    left: 60px;
    width: calc(100% - 60px);
  }
}

/* Contact Table css Start */

.table-responsive {
  padding: 10px 30px;
  margin-top: 40px;
  height: 70vh;
  width: 95% !important;
  /* border: 0.1px solid grey; */
}
.table-responsive table {
  border: 0.1px solid grey;
}

.table_container {
  width: 40px;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* max-width: 1%; */
}

/* Contact Table css End */

/* Admin table css start */
.user_dropdown {
  position: absolute;
  display: flex;
  align-items: center;
  gap: 15px;
  background: #f5f6fa;
  border-radius: 6px;
  height: 50px;
  min-width: 190px;
  padding: 0 15px 0 15px;
  cursor: pointer;
  margin-top: 100px;
  margin-left: -10px;

  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
  background-color: white;
  /* font-size: 25px; */
  /* color: #333; */
}
.admin_btn {
  width: 100px;
  margin: auto;
  background-color: rgb(52, 30, 148);
  color: white;
  padding: 8px 10px;
}

/* Admin Table css end */

/* Register user css start */

.register_user_btn {
  position: absolute;
  right: 30px;
}

.register_user_btn button {
  padding: 8px 20px;
  background-color: rgb(52, 30, 148);
  color: white;
  border-radius: 2px;
}

.Register_container {
  /* height: 100vh; */
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none !important;
  /* background-image: radial-gradient( */
  /* circle farthest-corner at 10% 20%, */
  /* rgba(253, 101, 133, 1) 0%, */
  /* rgba(255, 211, 165, 1) 90% */
  /* ); */
}

.Register_container .card {
  width: 400px;
  height: 420px;
  overflow: scroll;
  border: none !important;
  padding: 10px 30px;
}

.Register_container .card_title {
  text-align: center;
  padding: 10px;
}

.Register_container .card_title h1 {
  font-size: 26px;
  font-weight: bold;
}

.Register_container .form input,
.Register_container .form textarea {
  margin: 10px 0;
  width: 100%;
  background-color: #e2e2e2;
  border: none;
  outline: none;
  padding: 12px 20px;
  border-radius: 4px;
}

.Register_container .form button {
  background-color: rgb(52, 30, 148);
  color: #ffffff;
  font-size: 16px;
  outline: none;
  border-radius: 5px;
  border: none;
  padding: 8px 15px;
  width: 100%;
}

.Register_container .card_terms {
  display: flex;
  align-items: center;
  padding: 10px;
}

.Register_container .card_terms input[type="checkbox"] {
  background-color: #e2e2e2;
}

.Register_container .card_terms span {
  margin: 5px;
  font-size: 13px;
}

.Register_container .card a {
  color: #4796ff;
  text-decoration: none;
}
.close_modal {
  position: absolute;
  right: 6px;
  cursor: pointer;
}

/* Register User End */

/* Pagination Table css start */

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pagination span,
.pagination button {
  border: 0.1px solid grey;
  padding: 5px 12px;
}

.pagination button {
  background-color: rgb(52, 30, 148);
  color: white;
}

.pagination .disable_btn {
  background-color: grey;
}

.pagination .current_page {
  background-color: grey;
  color: white;
}

/* Pagination css End */

/* Logon css Start */

/* Admin Login Css start */

article,
aside,
details,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}

.Login_Container h1,
.Login_Container h2,
.Login_Container h3,
.Login_Container h4,
.Login_Container h5,
.Login_Container h6,
.Login_Container p,
.Login_Container a {
  -ms-word-wrap: break-word;
  word-wrap: break-word;
  text-decoration: none;
}

.Login_Container img {
  border: none;
}

*:focus {
  outline: none;
}

.clearfix::after {
  content: "";
  display: table;
  clear: both;
}

.bg-illustration {
  position: relative;
  height: 100vh;
  width: 1194px;
  background: url("https://i.ibb.co/RhMZprS/jony-Image2-1.png") no-repeat center
    center scroll;
  background-size: cover;
  float: left;
  -webkit-animation: bgslide 2.3s forwards;
  animation: bgslide 2.3s forwards;
}
.bg-illustration img {
  width: 248px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: auto;
  margin: 19px 0 0 25px;
}

@-webkit-keyframes bgslide {
  from {
    left: -100%;
    width: 0;
    opacity: 0;
  }
  to {
    left: 0;
    width: 1194px;
    opacity: 1;
  }
}

@keyframes bgslide {
  from {
    left: -100%;
    width: 0;
    opacity: 0;
  }
  to {
    left: 0;
    width: 1194px;
    opacity: 1;
  }
}

.login {
  max-height: 100vh;
  overflow-y: auto;
  float: left;
  margin: 0 auto;
  width: calc(100% - 1194px);
}
.login .container {
  width: 505px;
  margin: 0 auto;
  position: relative;
}
.login .container h1 {
  margin-top: 40px;
  font-size: 35px;
  font-weight: bolder;
}
.login .container .login-form {
  margin-top: 70px;
}
.login .container .login-form form {
  display: -ms-grid;
  display: grid;
}
.login .container .login-form form input {
  font-size: 16px;
  font-weight: normal;
  background: rgba(57, 57, 57, 0.07);
  margin: 12.5px 0;
  height: 68px;
  border: none;
  padding: 0 30px;
  border-radius: 10px;
}
.login .container .login-form form button[type="submit"] {
  background: -webkit-linear-gradient(110deg, #f794a4 0%, #fdd6bd 100%);
  background: -o-linear-gradient(110deg, #f794a4 0%, #fdd6bd 100%);
  background: linear-gradient(-20deg, #f794a4 0%, #fdd6bd 100%);
  border: none;
  margin-top: 80px;
  margin-bottom: 20px;
  width: 241px;
  height: 58px;
  text-transform: uppercase;
  color: white;
  border-radius: 10px;
  position: relative;
  z-index: 2;
  font-weight: bold;
  font-size: 20px;
}
.login .container .login-form form button[type="submit"]:hover::after {
  opacity: 1;
}
.login .container .login-form form button[type="submit"]::after {
  content: "";
  position: absolute;
  z-index: -1;
  border-radius: 10px;
  opacity: 0;
  top: 0;
  left: 0;
  -webkit-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  right: 0;
  bottom: 0;
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(#09203f),
    to(#537895)
  );
  background: -webkit-linear-gradient(bottom, #09203f 0%, #537895 100%);
  background: -o-linear-gradient(bottom, #09203f 0%, #537895 100%);
  background: linear-gradient(to top, #09203f 0%, #537895 100%);
}
.login .container .remember-form {
  position: relative;
  margin-top: -30px;
}
.login .container .remember-form input[type="checkbox"] {
  margin-top: 9px;
}
.login .container .remember-form span {
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  top: 32px;
  color: #3b3b3b;
  margin-left: 15px;
}
.login .container .forget-pass {
  position: absolute;
  right: 0;
  margin-top: 189px;
}
.login .container .forget-pass a {
  font-size: 16px;
  position: relative;
  font-weight: normal;
  color: #918f8f;
}
.login .container .forget-pass a::after {
  content: "";
  position: absolute;
  height: 2px;
  width: 100%;
  border-radius: 100px;
  background: -webkit-linear-gradient(110deg, #f794a4 0%, #fdd6bd 100%);
  background: -o-linear-gradient(110deg, #f794a4 0%, #fdd6bd 100%);
  background: linear-gradient(-20deg, #f794a4 0%, #fdd6bd 100%);
  bottom: -4px;
  left: 0;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  opacity: 0;
  right: 0;
}
.login .container .forget-pass a:hover::after {
  opacity: 1;
}

@media only screen and (min-width: 1024px) and (max-width: 1680px) {
  .bg-illustration {
    width: 50%;
    -webkit-animation: none;
    animation: none;
  }

  .login {
    width: 50%;
  }
}
/* Display 12", iPad PRO Portrait, iPad landscape */
@media only screen and (max-width: 1024px) {
  @-webkit-keyframes slideIn {
    from {
      left: -100%;
      opacity: 0;
    }
    to {
      left: 0;
      opacity: 1;
    }
  }

  @keyframes slideIn {
    from {
      left: -100%;
      opacity: 0;
    }
    to {
      left: 0;
      opacity: 1;
    }
  }
  .bg-illustration {
    float: none;
    background: url("https://i.ibb.co/rwncw7s/bg-mobile.png") center center;
    background-size: cover;
    -webkit-animation: slideIn 0.8s ease-in-out forwards;
    animation: slideIn 0.8s ease-in-out forwards;
    width: 100%;
    height: 190px;
    text-align: center;
  }
  .bg-illustration img {
    width: 100px;
    height: auto;
    margin: 20px auto !important;
    text-align: center;
  }
  .bg-illustration .burger-btn {
    left: 33px;
    top: 29px;
    display: block;
    position: absolute;
  }
  .bg-illustration .burger-btn span {
    display: block;
    height: 4px;
    margin: 6px;
    background-color: #fff;
  }
  .bg-illustration .burger-btn span:nth-child(1) {
    width: 37px;
  }
  .bg-illustration .burger-btn span:nth-child(2) {
    width: 28px;
  }
  .bg-illustration .burger-btn span:nth-child(3) {
    width: 20px;
  }

  .login {
    float: none;
    margin: 0 auto;
    width: 100%;
  }
  .login .container {
    -webkit-animation: slideIn 0.8s ease-in-out forwards;
    animation: slideIn 0.8s ease-in-out forwards;
    width: 85%;
    float: none;
  }
  .login .container h1 {
    font-size: 25px;
    margin-top: 40px;
  }
  .login .container .login-form {
    margin-top: 90px;
  }
  .login .container .login-form form input {
    height: 45px;
  }
  .login .container .login-form form button[type="submit"] {
    height: 45px;
    margin-top: 100px;
  }
  .login .container .login-form .remember-form {
    position: relative;
    margin-top: -14px;
  }
  .login .container .login-form .remember-form span {
    font-size: 16px;
    margin-top: 22px;
    top: inherit;
  }

  .forget-pass {
    position: absolute;
    right: inherit;
    left: 0;
    bottom: -40px;
    margin: 0 !important;
  }
  .forget-pass a {
    font-size: 16px;
    position: relative;
    font-weight: normal;
    color: #918f8f;
  }
  .forget-pass a::after {
    content: "";
    position: absolute;
    height: 2px;
    width: 100%;
    border-radius: 100px;
    background: -webkit-linear-gradient(110deg, #f794a4 0%, #fdd6bd 100%);
    background: -o-linear-gradient(110deg, #f794a4 0%, #fdd6bd 100%);
    background: linear-gradient(-20deg, #f794a4 0%, #fdd6bd 100%);
    bottom: -4px;
    left: 0;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    opacity: 0;
    right: 0;
  }
  .forget-pass a:hover::after {
    opacity: 1;
  }
}

/* Admin Login Css End */

/* Logon css End */

@media screen and (min-width: 100px) and (max-width: 480px) {
  input[type="datetime-local"]:not(.has-value):before {
    color: lightgray;
    content: attr(placeholder);
  }
}

/* New Css Add */

/* Hear from us css start */
.hear_from_student {
  background-color: #f7f7f7;
  padding: 40px 0px;
}
.hear_from_student > div h3 {
  padding: 20px 0;
}

.Top_Color {
  padding: 4px 0;
  background-color: #00632a;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.std_main_box {
  width: 100%;

  margin: auto;
  padding: 15px;

  /* width: 100% !important; */
  overflow: hidden;
}
.std_box {
  /* flex-grow: 1; */
  /* flex-basis: 250px; */
  width: 295px !important;
  height: 370px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  background-color: white;
  padding: 10px 0px;
  position: relative;
}
.std_box_img {
  padding: 10px;
}
.std_box_img img {
  width: 100%;
}
.std_box > div:nth-child(2) {
  padding: 10px;
}
/* @media screen and (min-width: 200px) and (max-width: 500px) { */
/* .std_main_box { */
/* display: flex; */
/* flex-direction: column; */
/* } */
/* .hear_from_student { */
/* height: auto; */
/*  */
/* padding: 30px; */
/* } */
/* .Destination { */
/* width: 100%; */
/* } */
/* } */
.divider {
  border-bottom: 0.1px solid #1bdc82;
  width: 60px;
  display: block;
  line-height: 1.2em;
}

.divider2 {
  border-bottom: 0.1px solid #1bdc82;
  width: 60px;
  display: block;
  line-height: 1.2em;
  margin-bottom: 30px;
}

/* Footer css end */

/* Slider css start */

.searchcountryslide {
  padding-right: 12px;
  padding-left: 12px;
  padding-top: 12px;
  padding-bottom: 35px;
  width: 280px !important;
}
.Back_Btn {
  padding: 10px 28px;
  font-size: 17px;
  border: none;

  color: white;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 20px;
  background-color: grey;
}

.Back_Btn:hover {
  background-color: rgb(136, 136, 136);
}
/* .content h3 { */
/* letter-spacing: 2px; */
/* font-size: 22px; */
/* } */
.Box_style {
  width: 200px;
  height: 200px;
  padding: 15px;
  margin: auto;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
  position: relative;
}
.Inner_Box {
  background-color: rgb(244, 244, 244);
  width: 100%;
  height: 100%;
  border: 2px solid rgb(212, 212, 212);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 3px;
}
.Inner_Box p {
  text-align: center;
  font-size: 19px !important;
  font-weight: 600;
  color: rgb(39, 39, 39);
  letter-spacing: 1px;
  /* transform: translateX(-40%) translateY(-50%); */
  /* font-weight: 600; */
  /* font-size: 16px !important; */
  /* color: rgb(39, 39, 39); */
  /* position: absolute; */
  /* top: 50%; */
  /* left: 40%; */
}
.Fancy_Box2 {
  position: absolute !important;
  bottom: 0 !important;
  left: 0 !important;
  border-bottom: 120px solid #00632a;
  border-right: 200px solid transparent;
  border-bottom-left-radius: 15px;

  /* border-bottom-right-radius: 15px; */
}
.std_logo {
  position: absolute;
  bottom: 20px;
  left: 55px;
}
.std_logo img {
  width: 33%;
}

@media screen and (min-width: 200px) and (max-width: 780px) {
  .std_box {
    padding: 10px 10px;
  }
  .std_main_box {
    overflow: hidden;
    padding: 10px;
  }
  .std_box > div:nth-child(2) {
    padding: 10px;
  }
  .FAQ {
    display: flex;
    flex-direction: column;
  }
  .FAQ > div:nth-child(1) img {
    width: 100%;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
  .FAQ > div:nth-child(1) {
    background-color: #c9c7c6;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
      rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
      rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  }
  .FAQ > div:nth-child(2) {
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px -50px 36px -28px inset;
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, */
    /* rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset; */
  }

  .Radius {
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
  }
  .Radius2 {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  .Point_Section > div p {
    color: white;
    font-size: 14px;
  }
  .Point_Section {
    padding: 10px;
  }
  .Point_Section > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    gap: 10px;
    font-size: 23px;
  }
  .Hotel_Point .Point_Section > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: white;
    gap: 25px;
    font-size: 25px;
  }
  .TextHeight {
    font-size: 12px;
    padding: 5px;
  }
}
.TextHeight {
  font-size: 13px;
  padding: 5px;
}
/* Section 2 Rotation css start */
.Rotation {
  width: 100%;
  background-color: #084510;
  padding: 20px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
}

.Rotation > div {
  width: 100%;
  display: flex;
  gap: 10px;
  color: white;
  align-items: center;
  font-size: 14px;
}
.Rotation > div > span:nth-child(1) {
  font-size: 18px;
}
.marqueeStyle span {
  font-weight: bold;
}

.marqueeStyle {
  display: inline-block;
  /* Apply animation to this element */
  -webkit-animation: scrolling-left1 20s linear infinite;
  animation: scrolling-left1 20s linear infinite;
}

.marqueeStyle2 {
  display: inline-block;
  /* Apply animation to this element */
  -webkit-animation: scrolling-left2 20s linear infinite;
  animation: scrolling-left2 20s linear infinite;
  animation-delay: 10s;
}
/* scrolling-left is continuous/repeatly text */
@keyframes scrolling-left1 {
  0% {
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
  }
}
@keyframes scrolling-left2 {
  0% {
    transform: translateX(0%);
    -webkit-transform: translateX(0%);
  }
  100% {
    transform: translateX(-200%);
    -webkit-transform: translateX(-200%);
  }
}

@-webkit-keyframes scrolling-left1 {
  0% {
    -webkit-transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
  }
}
@-webkit-keyframes scrolling-left2 {
  0% {
    -webkit-transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(-200%);
  }
}

@media screen and (min-width: 200px) and (max-width: 680px) {
  .Rotation > div {
    width: 100%;
    display: flex;
    gap: 10px;
    color: white;
    align-items: center;
    font-size: 8px;
  }
  .marqueeStyle span {
    font-size: 13px;
  }
}

/* Section 2 Rotation css end */
